exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[type]-[id]-tsx": () => import("./../../../src/pages/[type]/[id].tsx" /* webpackChunkName: "component---src-pages-[type]-[id]-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-post-[id]-tsx": () => import("./../../../src/pages/post/[id].tsx" /* webpackChunkName: "component---src-pages-post-[id]-tsx" */),
  "component---src-pages-products-[id]-tsx": () => import("./../../../src/pages/products/[id].tsx" /* webpackChunkName: "component---src-pages-products-[id]-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */)
}

