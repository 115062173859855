import React, { useContext } from "react"
import { ServerSliceRenderer as OpenSourceServerSliceRenderer } from "./server-slice-renderer.js.original"
import { SlicesContext } from "./context"

export const ServerSliceRenderer = ({ sliceId, children, ...restProps }) => {
  const slicesContext = useContext(SlicesContext)

  // special case for "gatsby-scripts" special slice in engine context so we use open source implementation and
  // allow engine to string replace the scripts
  if (
    slicesContext?.renderEnvironment === `engines` &&
    sliceId === `_gatsby-scripts`
  ) {
    return (
      <OpenSourceServerSliceRenderer sliceId={sliceId} {...restProps}>
        {children}
      </OpenSourceServerSliceRenderer>
    )
  }

  const contents = [
    React.createElement(`esi:include`, {
      src: `${__PATH_PREFIX__}/_gatsby/slices/${sliceId}-1.html`,
    }),
  ]

  if (children) {
    // if children exist, we split the Slice into a before and after piece
    // see renderSlices in render-html
    contents.push(children)
    contents.push(
      React.createElement(`esi:include`, {
        src: `${__PATH_PREFIX__}/_gatsby/slices/${sliceId}-2.html`,
      })
    )
  }

  return contents
}
